export const isEmpty = (object) => Object.values(object).some((x) => x === null || x === "");

export const toBase64 = (file) =>
    new Promise((resolve, reject) => {
        if (!(file instanceof Blob)) {
            return resolve("");
        }
        const fileSizeMb = file.size / 1024 / 1024;
        if (fileSizeMb > 5 && fileSizeMb < 10) {
            return resizeMe(file, 0.015).then(resolve);
        } else if (fileSizeMb > 10) {
            return resizeMe(file, 0.075).then(resolve);
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export const ActiveTabEnum = {
    StartPage: 1,
    CompanyInfo: 2,
    AccountInfo: 3,
    CheckInfo: 4,
};

export function backgroundSort(a, b) {
    if (a.name.length > b.name.length) {
        return 1;
    } else {
        return -1;
    }
}

const resizeMe = (file, to = 0.7) =>
    new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);

        reader.onload = function (event) {
            const blob = new Blob([event.target.result]); // create blob...
            window.URL = window.URL || window.webkitURL;
            const blobURL = window.URL.createObjectURL(blob); // and get it's URL

            const image = new Image();
            image.src = blobURL;
            image.onload = function () {
                const canvas = document.createElement("canvas");

                const width = image.width;
                const height = image.height;

                // // calculate the width and height, constraining the proportions
                // if (width > height) {
                //     if (width > max_width) {
                //         //height *= max_width / width;
                //         height = Math.round((height *= max_width / width));
                //         width = max_width;
                //     }
                // } else {
                //     if (height > max_height) {
                //         //width *= max_height / height;
                //         width = Math.round((width *= max_height / height));
                //         height = max_height;
                //     }
                // }

                // resize the canvas and draw the image data into it
                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(image, 0, 0, width, height);

                resolve(canvas.toDataURL("image/jpeg", to)); // get the data from canvas as 70% JPG (can be also PNG, etc.)
            };
        };
    });

export const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data);
        }
    });
}