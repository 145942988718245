export const defaultCompanyInfo = {
    companyName: "",
    companyAddress: "",
    companyAddress2: "",
    city: "",
    state: "",
    zip: "",
    cityStateZip: "-",
    logoType: "0",
    customerWillProvideImageOnEmail: false,
    logoPosition: "0",
    logoColor: "0",
    phoneNumber: "",
    additionalInformation: "",
    logoBase64: ""
}

export const defaultAccountInfo = {
    bankName: "",
    bankAddress: "",
    bankCity: "",
    bankState: "",
    bankZip: "",
    bankCityStateZip: "-",
    nineDigitRoutingNumber: "",
    accountNumber: "",
    confirmAccountNumber: "",
    routingFractionNumber: ""
}

export const defaultCheckInfo = {
    checkStartingNumber: "",
    linesOnCheck: false,
    extraSignatureLine: false,
    reverseNumbering: false,
    textAboveSignatureLine: "",
    userWillProvideAVoidedCheck: false,
    proofBeforePrinting: false,
    comment: ""
};

export const defaultPersonalizeInfo = {
    quantity: null,
    quantityOverride: null,
    selectedBackgroundId: null
}