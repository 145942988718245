import { fabric } from 'fabric';
import { BackEndUrl } from './requests';

const numberFields = [
    "9 digit routing number",
    "Account number",
    "Check starting number bottom"
];

const blockedBoldFields = [
    "nineDigitRoutingNumner",
    "accountNumber",
    "routingFractionNumber",
    "checkStartingNumber",
    "checkStartingNumberBottom",
    "textAboveSignatureLine",
];

const companyInfoFields = [
    "Company Name",
    "Company Address",
    "Company Address 2",
    "Phone number",
    "Additional information",
    "City State Zip"
];

const accountInfoFields = [
    "bankName",
    "bankAddress",
    "bankCityStateZip",
    "routingFractionNumber",
    "nineDigitRoutingNumber",
    "accountNumber"
]

// const accountInfoFields = [
//     "Bank name",
//     "Bank address",
//     "Bank City State Zip"
// ];

const boldKeys = {
    companyNameBold: ["Company Name"],
    companyAddressBold: ["Company Address"],
    companyAddress2Bold: ["Company Address 2"],

    cityBold: ["City"],
    stateBold: ["State"],
    zipBold: ["Zip"],
    cityStateZipBold: ["City State Zip"],
    bankCityStateZipBold: ["Bank City State Zip"],
    bankNameBold: ["Bank name"],
    bankAddressBold: ["Bank address"],
    bankCityBold: ["Bank city"],
    bankStateBold: ["Bank state"],
    bankZipBold: ["Bank zip"],
    checkStartingNumberBold: ["Check starting number", "Check starting number bottom"],
    nineDigitRoutingNumberBold: ["9 digit routing number"],
    accountNumberBold: ["Account number"],
    routingFractionNumberBold: ["Routing fraction number"],
    textAboveSignatureLineBold: ["Text above signature line"],
    phoneNumberBold: ["Phone number"],
    additionalInformationBold: ["Additional information"],
}

export const boldHandler = (canvas, fieldMapping, name, checked) => {
    if (!name.endsWith("Bold")) {
        return;
    }
    const keys = boldKeys[name];
    const canvasObjects = fieldMapping.filter(x => keys.includes(x.field));
    if (!canvasObjects?.length) {
        return;
    }
    for (const canvasObject of canvasObjects) {
        const annotation = canvasObject.annotation;
        if (annotation) {
            annotation.fontWeight = checked ? "bold" : "normal";
            canvas.renderAll();
            fixItemWidth(canvas, canvasObject.width, annotation);
        }
    }
}

const addSignatureText = (canvas, objects) => {
    /** @type {fabric.Text} */
    let bothSignaturesAreRequired = objects.find((x) => x.fill === "bothSignaturesAreRequired");
    let width = bothSignaturesAreRequired?.width;
    if (!bothSignaturesAreRequired) {
        /** @type {fabric.Line} */
        const signatureLine = objects.find((x) => x.fill === "signatureLine");
        if (!signatureLine) {
            return;
        }

        bothSignaturesAreRequired = new fabric.Text("Authorized signature", {
            fontSize: 11,
            fontWeight: "lighter", // not changing anything
            left: signatureLine.getCoords()[0].x + signatureLine.width / 2,
            width: signatureLine.width,
            top: signatureLine.top + 2,
            fill: "bothSignaturesAreRequired",
        });
        width = signatureLine.width;
    }
    bothSignaturesAreRequired.lockMovementX = true;
    bothSignaturesAreRequired.lockMovementY = true;
    bothSignaturesAreRequired.lockUniScaling = true;
    bothSignaturesAreRequired.hasControls = false;
    bothSignaturesAreRequired.borderColor = "transparent";
    bothSignaturesAreRequired.textAlign = "center";
    canvas.add(bothSignaturesAreRequired);
    canvas.setActiveObject(bothSignaturesAreRequired)
    bothSignaturesAreRequired.width = width;
};

export const keysByField = {
    "Company Name": "companyName",
    "Company Address": "companyAddress",
    "Company Address 2": "companyAddress2",
    City: "city",
    State: "state",
    Zip: "zip",
    "City State Zip": "cityStateZip",
    "Bank City State Zip": "bankCityStateZip",
    "Bank name": "bankName",
    "Bank address": "bankAddress",
    "Bank city": "bankCity",
    "Bank state": "bankState",
    "Bank zip": "bankZip",
    "Check starting number": "checkStartingNumber",
    "Check starting number bottom": "checkStartingNumber",
    "9 digit routing number": "nineDigitRoutingNumber",
    "Account number": "accountNumber",
    "Routing fraction number": "routingFractionNumber",
    "Text above signature line": "textAboveSignatureLine",
    "Phone number": "phoneNumber",
    "Additional information": "additionalInformation",
};

function fixItemWidth(canvas, initialWidth, annotation) {
    if (annotation && initialWidth && initialWidth > annotation.width && annotation.textAlign !== "left") {
        annotation.width = initialWidth;
        canvas.renderAll();
    }
}

export const intializeCanvas = (canvas, params, linesOnCheck, callback) => {
    canvas.clear();
    const { productVariant, background } = params;

    canvas.setDimensions({ width: productVariant.width, height: productVariant.height });

    const backgroundImageUrl = `${BackEndUrl}/uploads/${background.filePath}`;
    const checkTransparentImageUrl = `${BackEndUrl}/uploads/${productVariant.imageFileName}`;

    fabric.Image.fromURL(backgroundImageUrl, function (img) {
        canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
            scaleX: productVariant.width / img.width,
            scaleY: productVariant.height / img.height
        });
    }, {
        crossOrigin: 'anonymous'
    });

    fabric.Image.fromURL(checkTransparentImageUrl, function (img) {
        img.set({
            scaleX: productVariant.width / img.width,
            scaleY: productVariant.height / img.height
        });
        canvas.add(img)
    }, {
        hasControls: false,
        evented: false,
        lockMovementX: true,
        lockMovementY: true,
        crossOrigin: 'anonymous'
    });

    const fieldMapping = [];
    const isRequiredMap = {};
    const isBoldMap = {};
    const objectsArrayNotFiltered = JSON.parse(productVariant.placeholdersJson);
    // commented out since the lines were not working
    // console.log(objectsArrayNotFiltered);
    const objectsArray = objectsArrayNotFiltered.filter(
        (item, index) => index === objectsArrayNotFiltered.findIndex((i) => i.fill === item.fill)
    );
    const objectsMap = {};
    for (const item of objectsArray) {
        objectsMap[item.text] = item;
    }
    fabric.util.enlivenObjects(objectsArrayNotFiltered, objects => {
        for (const o of objects) {
            fieldMapping.push({ field: o.text, annotation: o });
            if (o.fill === "companyName" || o.fill == "bankName") {
                o.fontWeight = "bold";
                o.fontWeight = "bold";
            }
            if (o.type === "text" && keysByField[o.text]) {
                isRequiredMap[keysByField[o.text]] = o.isRequired;
                if (!blockedBoldFields.includes(keysByField[o.text])) {
                    isBoldMap[keysByField[o.text]] = o.isBold;
                }
            }
            if (o.type === "text") {
                o.width = objectsMap[o.text]?.width || o.width;
                fieldMapping[fieldMapping.length - 1].width = o.width;
                fieldMapping[fieldMapping.length - 1].textAlign = o.textAlign;
            }
            if (numberFields.indexOf(o.text) > -1) {
                o["fontFamily"] = "micrenc";
            }

            o["lockMovementX"] = true;
            o["lockMovementY"] = true;
            o["lockUniScaling"] = true;
            o["hasControls"] = false;

            if (o.type === "path" || o.type === "rect" || o.fill === "canvas-image") {
                if (o.fill !== "signatureLine") {
                    o["visible"] = linesOnCheck;
                }
                o["borderColor"] = "transparent";
            }
            else {
                o.text = '';
                o["borderColor"] = "red";
            }

            if (o.fill === "extraSignatureLine") {
                o.visible = false;
                addSignatureText(canvas, objects);
            }
            if (o.fill === "bothSignaturesAreRequired") {
                continue;
            }

            canvas.add(o);
        }

        canvas.renderAll();
        callback({ fieldMapping, isRequiredMap, isBoldMap });
        removeCompanyAddress2IfEmpty(canvas, fieldMapping, null);
    });
}

export const resizeCanvas = (canvas, productVariant) => {
    const outerCanvasContainer = document.getElementsByClassName('preview_image')[0];
    if (!canvas || !outerCanvasContainer || !productVariant) {
        return;
    }

    const ratio = canvas.getWidth() / canvas.getHeight();
    const containerWidth = outerCanvasContainer.clientWidth;

    const scale = containerWidth / canvas.getWidth();
    const zoom = canvas.getZoom() * scale;

    if (containerWidth < productVariant.width) {
        canvas.setDimensions({ width: containerWidth });
        canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
    }

    if ((containerWidth / ratio) < productVariant.height) {
        canvas.setDimensions({ height: containerWidth / ratio });
        canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
    }
}

const getKeys = propName => {
    switch (propName) {
        case 'companyName':
            return ["Company Name"];
        case 'companyAddress':
            return ["Company Address"];
        case 'companyAddress2':
            return ["Company Address 2"];
        case 'city':
            return ["City State Zip"];
        case 'state':
            return ["City State Zip"];
        case 'zip':
            return ["City State Zip"];
        case 'cityStateZip':
            return ["City State Zip"];
        case 'bankName':
            return ["Bank name"]
        case 'bankAddress':
            return ["Bank address"];
        case 'bankCity':
            return ["Bank city"];
        case 'bankState':
            return ["Bank state"];
        case 'bankZip':
            return ["Bank zip"];
        case 'bankCityStateZip':
            return ["Bank City State Zip"];
        case 'checkStartingNumber':
            return ["Check starting number", "Check starting number bottom"]
        case 'nineDigitRoutingNumber':
            return ["9 digit routing number"];
        case 'accountNumber':
            return ["Account number"];
        case 'routingFractionNumber':
            return ["Routing fraction number"];
        case 'textAboveSignatureLine':
            return ["Text above signature line"];
        case 'phoneNumber':
            return ["Phone number"];
        case 'additionalInformation':
            return ["Additional information"];
        default:
            return []
    }
}

export const updateCanvasValues = (canvas, fieldMapping, key, value) => {
    const keys = getKeys(key);
    for (const key of keys) {
        const canvasObject = fieldMapping.find(x => x.field === key);
        if (!canvasObject) {
            console.log("canvas.js, -Not found", canvasObject);
            continue;
        }

        const annotation = canvasObject.annotation;
        if (annotation) {
            annotation.text = key === "Check starting number bottom" && value ? `00${value}` : value;
            canvas.renderAll();
            fixItemWidth(canvas, canvasObject.width, annotation);
        }
    }

    removeCompanyAddress2IfEmpty(canvas, fieldMapping, null);
}

export const extraSignatureChangeHandler = (canvas, name, checked) => {
    if (name !== "extraSignatureLine") {
        return;
    }
    const objects = canvas.getObjects();

    const extraSignatureLineObject = objects.find((x) => x.fill === "extraSignatureLine");
    const bothSignaturesAreRequired = objects.find((x) => x.fill === "bothSignaturesAreRequired");
    const width = bothSignaturesAreRequired?.width;
    if (bothSignaturesAreRequired) {
        bothSignaturesAreRequired.text = (Boolean(checked) && extraSignatureLineObject) ? "Two signatures required" : "Authorized signature";
    }
    if (!extraSignatureLineObject) {
        return;
    }

    extraSignatureLineObject.visible = Boolean(checked);
    canvas.renderAll();
    fixItemWidth(canvas, width, bothSignaturesAreRequired);
};

export const highlightAnnotation = (canvas, fieldMapping, e) => {
    let keys = getKeys(e.target.name);

    for (let key of keys) {
        let el = fieldMapping.find(x => x.field === key);
        if (el) {
            let ann = el.annotation;
            if (!ann.visible) {
                continue;
            }
            canvas.setActiveObject(ann);
            canvas.renderAll();
            fixItemWidth(canvas, el.width, ann);
        }
    }
}

export const linesOnCheckHandler = (canvas, name, checked) => {
    /** @type {Array<fabric.Object>} */
    const objects = canvas.getObjects();

    if (name === "linesOnCheck") {
        const linesOnCheckObjects = objects.filter(
            (x) =>
                (x.type === "path" &&
                    x.fill !== "extraSignatureLine" &&
                    x.fill !== "signatureLine") ||
                x.type === "rect" ||
                x.fill === "canvas-image"
        );
        if (linesOnCheckObjects.length > 0) {
            for (const lineOnCheck of linesOnCheckObjects) {
                lineOnCheck.visible = Boolean(checked);
            }
        }

        canvas.renderAll();
    }
}

export const updateValuesWrapper = (canvas, fieldMapping, state) => {

    if (!canvas || !fieldMapping) {
        return;
    }

    for (const p of Object.keys(state)) {
        if (['cityStateZip', 'bankCityStateZip'].includes(p)) {
            continue;
        }
        if (["city", "state", "zip"].includes(p)) {
            updateCanvasValues(canvas, fieldMapping, "cityStateZip", `${state.city} ${state.state} ${state.zip}`);
            continue;
        }
        if (["bankCity", "bankState", "bankZip"].includes(p)) {
            updateCanvasValues(
                canvas,
                fieldMapping,
                "bankCityStateZip",
                `${state.bankCity} ${state.bankState} ${state.bankZip}`,
            );
            continue;
        }

        updateCanvasValues(canvas, fieldMapping, p, state[p]);
    }
}

/**
 *
 * @param {fabric.Canvas} canvas
 * @param {*} position
 * @param {*} fieldMapping
 * @param {*} fieldMappingDefaultPositions
 * @returns
 */
export const changeLogoPosition = (canvas, position, fieldMapping, fieldMappingDefaultPositions) => {
    const objects = canvas.getObjects();
    const logoElement = objects.find(x => x.fill === "logo");
    if (!logoElement) {
        return false;
    }

    revertBackAllPositions(canvas, fieldMapping, fieldMappingDefaultPositions);

    switch (+position) {
        case 1:
            insteadOfCompanyName(canvas, fieldMapping, logoElement);
            break;
        case 2:
            insteadOfCompanyNameAndInfo(fieldMapping);
            break;
        default:
            moveToSideOfImprint(objects, logoElement);
            break;
    }

    canvas.discardActiveObject();
    canvas.renderAll();
    return true;
    // handleAdditionalInformation(canvas, fieldMapping);
}

// function handleAdditionalInformation(canvas, fieldMapping) {
//     const additionalInformation = fieldMapping.find(item => item.field === "Additional information");
//     if (additionalInformation && additionalInformation.annotation.visible) {
//         const companyName = fieldMapping.find(item => item.field === "Company Name");
//         additionalInformation.annotation.left = companyName.annotation.left + companyName.annotation.width + 10;
//         canvas.renderAll();
//     }
// }

export const addLogo = (url, canvas, position, fieldMapping, fieldMappingDefaultPositions, logoColor = "1") => {
    const images = canvas.getObjects("image");
    const y = canvas.getObjects("text").find(x => x.fill == "companyName").getCoords()[0].y;
    /** @type {fabric.Image} */
    const logoElement = images.find(i => i.fill === "logo");
    if (logoElement) {
        canvas.remove(logoElement)
    }
    fabric.Image.fromURL(url, function (img) {
        // const companyName = fieldMapping.find(x => x.field === "Company Name");
        // const top = companyName.annotation.top > 48 ? 15 : companyName.annotation.top;
        img.set({
            left: 15,
            top: y,
        });
        // as an option
        let height = 56;
        if (img.width / img.height > 5) {
            height = 32;
        } else if (img.width / img.height > 4) {
            height = 36;
        } else if (img.width / img.height > 3) {
            height = 40;
        } else if (img.width / img.height > 2) {
            height = 48;
        }

        img.scaleToHeight(height);
        img.scaleToWidth(height * (img.width / img.height));
        img.fill = "logo";
        img.visible = false;
        img.visible = true;
        img.lockMovementX = true;
        img.lockMovementY = true;
        img.lockUniScaling = true;
        img.hasControls = false;
        if (logoColor === "0") {
            img.applyFilters([new fabric.Image.filters.Grayscale()]);
        }

        canvas.add(img).setActiveObject(img).renderAll();

        changeLogoPosition(canvas, position, fieldMapping, fieldMappingDefaultPositions);
    }, {
        crossOrigin: "anonymous"
    });
}

export const clearLogo = (canvas, fieldMapping, fieldMappingDefaultPositions) => {
    const logo = canvas.getObjects().find(x => x.fill === "logo");
    if (logo) {
        canvas.remove(logo);
    }

    revertBackAllPositions(canvas, fieldMapping, fieldMappingDefaultPositions);
}

const revertBackAllPositions = (canvas, fieldMapping, fieldMappingDefaultPositions) => {
    for (const defaultPosition of fieldMappingDefaultPositions) {
        const _fieldMapping = fieldMapping.find(x => x.field === defaultPosition.field);
        if (_fieldMapping && _fieldMapping.annotation) {
            _fieldMapping.annotation.left = defaultPosition.annotation.left;
            _fieldMapping.annotation.top = defaultPosition.annotation.top;
            _fieldMapping.annotation.textAlign = defaultPosition.textAlign;
            _fieldMapping.annotation.visible = true;
            canvas.renderAll();
            fixItemWidth(canvas, _fieldMapping.width, _fieldMapping.annotation);
        }
    }
}

const moveToSideOfImprint = (canvasElements, logoElement) => {
    const companyNameField = canvasElements.find(x => x.fill == 'companyName');
    const logoElementTop = companyNameField.getCoords()[0].y + 10;

    const logoElementLeft = logoElement.left || 15;
    const movable = canvasElements.filter(
        (x) => x.top > 0 && x.top < 120 && x.left + 100 < 600 && x.fill !== "logo" && (x.type !== "text" || x.text)
    ).filter(x => !accountInfoFields.includes(x.fill));
    movable.sort((a, b) => a.top > b.top ? 1 : -1)
    let prevBottom = 0;
    const distance = Math.max((movable[1] && movable[0]) ? movable[1].top - (movable[0].top + movable[0].height) : 0, 0);
    for (const index in movable) {
        const mo = movable[index];
        if (index > 1) {
            mo.top = prevBottom + distance + 1;
        }
        mo.left = logoElement.width * logoElement.scaleX + 6 + logoElementLeft;
        const width = mo.width;
        mo.textAlign = "left";
        mo.width = width;
        prevBottom = mo.height + mo.top;
    }

    logoElement.left = 15;
    logoElement.top = Math.round(logoElementTop);
}

const insteadOfCompanyName = (canvas, fieldMapping, logoElement) => {
    toggleVisibilityOfField(fieldMapping, "Company Name", false);
    //toggleVisibilityOfField(fieldMapping, "Additional information", false);

    const canvasElements = canvas.getObjects();
    const restOfTheCompanyInfoFields = fieldMapping
        .filter(x => companyInfoFields.includes(x.field));

    const logoElementLeft = logoElement.left || 15;
    const movable = canvasElements.filter(
        (x) => x.top > 0 && x.top < 120 && x.left + 100 < 600 && x.fill !== "logo" && (x.type !== "text" || x.text)
    ).filter(x => !accountInfoFields.includes(x.fill));
    movable.sort((a, b) => a.top > b.top ? 1 : -1)
    let prevBottom = 0;
    const distance = Math.max((movable[1] && movable[0]) ? movable[1].top - (movable[0].top + movable[0].height) : 0, 0);
    for (const index in movable) {
        const mo = movable[index];
        if (index > 1) {
            mo.top = prevBottom + distance + 1;
        }
        mo.left = logoElement.width * logoElement.scaleX + 6 + logoElementLeft;
        const width = mo.width;
        mo.textAlign = "left";
        mo.width = width;
        prevBottom = mo.height + mo.top;
    }

    for (const element of restOfTheCompanyInfoFields) {
        const annotation = element.annotation;
        annotation.left = 20;
        annotation.top += logoElement.height * logoElement.scaleX - 10;
        annotation.textAlign = "left";
        fixItemWidth(canvas, element.width, annotation);
    }
}

const insteadOfCompanyNameAndInfo = (fieldMapping) => {
    for (const companyField of companyInfoFields) {
        toggleVisibilityOfField(fieldMapping, companyField, false);
    }

    // for (const accountField of accountInfoFields) {
    //     toggleVisibilityOfField(fieldMapping, accountField, false);
    // }
}

const toggleVisibilityOfField = (fieldMapping, key, visible) => {
    const field = fieldMapping.find(x => x.field === key);
    if (field) {
        field.annotation.visible = visible;
    }
}

export const removeCompanyAddress2IfEmpty = (canvas, fieldMapping, fieldMappingDefaultPositions) => {
    // console.clear();
    // console.log("Remove address 2");
    const canvasElements = canvas.getObjects();
    const address2Element = canvasElements.find(x => x.fill == "companyAddress2");
    //console.log(address2Element, "Add 2");
    if (address2Element.text != '') {
        if (fieldMappingDefaultPositions != null) {
            revertBackAllPositions(canvas, fieldMapping, fieldMappingDefaultPositions);
        }

        return;
    }

    const movable = canvasElements.filter(
        (x) => x.top > 0 && x.top < 120 && x.left + 100 < 600 && x.fill !== "logo" && (x.type !== "text" || x.text)
    ).filter(x => !accountInfoFields.includes(x.fill));
    movable.sort((a, b) => a.top > b.top ? 1 : -1)

    //console.log(movable, "Movable");
    // highlightAnnotation(canvas, fieldMapping, { target: { name: "cityStateZip" } });

    let prevBottom = 0;
    const distance = Math.max((movable[1] && movable[0]) ? movable[1].top - (movable[0].top + movable[0].height) : 0, 0);
    for (const index in movable) {
        const mo = movable[index];
        if (index > 1) {
            mo.top = prevBottom + distance + 1;
        }
        //mo.left = logoElement.width * logoElement.scaleX + 6 + logoElementLeft;
        const width = mo.width;
        //mo.textAlign = "left";
        //mo.width = width;
        prevBottom = mo.height + mo.top;
    }

    canvas.renderAll();
}