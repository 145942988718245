import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import "./personalizeForm.css";

const CheckInfo = ({ checkInfo, checkInfoFormChangeHandler, onVoidedCheckFileSelect, isBoldMap }) => {
    const commonProps = {
        data: checkInfo,
        onChange: checkInfoFormChangeHandler,
        isBoldMap,
        isBold: true,
    };

    return (
        <div className="personalizeFrom">
            <Form>
                <Row>
                    <Col sm={12} className="form-col">
                        <Form.Group className="mb-3">
                            <Form.Control
                                name="checkStartingNumber"
                                value={checkInfo.checkStartingNumber}
                                onChange={checkInfoFormChangeHandler}
                                type="number"
                                min={1}
                                placeholder="Check Starting Number"
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={12} className="form-col">
                        <div className="d-flex justify-content-between flex-wrap mb-3 py-2">
                            <Form.Check
                                name="linesOnCheck"
                                checked={checkInfo.linesOnCheck}
                                onChange={checkInfoFormChangeHandler}
                                inline
                                label="Lines on Check"
                                type="checkbox"
                                className="pb-2"
                                id="linesOnCheck"
                            />
                            <Form.Check
                                name="extraSignatureLine"
                                checked={checkInfo.extraSignatureLine}
                                onChange={checkInfoFormChangeHandler}
                                inline
                                label="Extra Signature Line"
                                type="checkbox"
                                className="pb-2"
                                id="extraSignatureLine"
                            />
                            <Form.Check
                                name="reverseNumbering"
                                checked={checkInfo.reverseNumbering}
                                onChange={checkInfoFormChangeHandler}
                                inline
                                label="Reverse Numbering"
                                type="checkbox"
                                className="pb-2"
                                id="reverseNumbering"
                            />
                        </div>
                    </Col>
                    <Col sm={12} className="form-col">
                        <Form.Control
                            className="mb-3"
                            value={checkInfo.textAboveSignatureLine}
                            onChange={checkInfoFormChangeHandler}
                            name="textAboveSignatureLine"
                            id="textAboveSignatureLine"
                            placeholder="Text Above Signature Line"
                        />
                    </Col>
                    <Col sm={12} className="form-col">
                        <div className="mb-3 py-2">
                            <Form.Check
                                name="userWillProvideAVoidedCheck"
                                checked={checkInfo.userWillProvideAVoidedCheck}
                                onChange={checkInfoFormChangeHandler}
                                inline
                                label="I will provide a voided check to guarantee accuracy"
                                type="checkbox"
                                className="pb-2"
                                id="userWillProvideAVoidedCheck"
                            />
                        </div>
                        {checkInfo.userWillProvideAVoidedCheck && (
                            <input
                                onChange={onVoidedCheckFileSelect}
                                className="form-control form-control-sm"
                                type="file"
                            />
                        )}
                    </Col>
                    <Col sm={12} className="form-col">
                        <hr />
                        <div className="">
                            <h4 className="mb-0">Proof Before Printing?</h4>
                            {checkInfo.proofBeforePrinting && (
                                <small className="text-danger">(Please note: this may delay your order)</small>
                            )}
                            <br />
                            <Form.Check
                                name="proofBeforePrinting"
                                value="true"
                                checked={checkInfo.proofBeforePrinting}
                                onClick={checkInfoFormChangeHandler}
                                inline
                                label="Yes"
                                type="radio"
                                className="pb-2"
                                id="proofBeforePrinting-yes"
                            />
                            <Form.Check
                                name="proofBeforePrinting"
                                value="false"
                                checked={checkInfo.proofBeforePrinting === false}
                                onClick={checkInfoFormChangeHandler}
                                inline
                                label="No"
                                type="radio"
                                className="pb-2"
                                id="proofBeforePrinting-no"
                            />
                        </div>
                        <hr />
                    </Col>
                    <Col sm={12} className="form-col pt-2">
                        <textarea
                            className="checkComment"
                            placeholder="Comment"
                            name="comment"
                            onChange={checkInfoFormChangeHandler}
                        />
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default CheckInfo;
