import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

import { Routes, Route, Navigate } from "react-router-dom";

import PersonalizeCheck from "./layouts/personalizeCheck/personalizeCheck.js";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate replace to="/personalize-check" />} />
          <Route path="/personalize-check" element={<PersonalizeCheck />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
