import { Form, InputGroup } from "react-bootstrap";

const FormItemWithBold = ({
    as: Control = Form.Control,
    data,
    onChange,
    errors,
    isBold,
    isBoldMap,
    name,
    skipBold = false,
    ...props
}) => (
    <>
        <Form.Group className={`mb-${!skipBold && (isBoldMap[`${name}Bold`] || isBold) ? 1 : 3}`}>
            <InputGroup className="mb-3 form-item-with-bold-input-group">
                <Control
                    onChange={onChange}
                    name={name}
                    value={data[name]}
                    isInvalid={errors && errors[name]}
                    {...props}
                />
                {errors && <Form.Control.Feedback type="invalid">{errors[name]}</Form.Control.Feedback>}
                {!skipBold && (isBoldMap[name] || isBold) && (
                    <>
                        <InputGroup.Text>Bold</InputGroup.Text>
                        <InputGroup.Checkbox
                            id={`${name}-bold-check`}
                            name={`${name}Bold`}
                            checked={data[`${name}Bold`] || false}
                            onChange={onChange}
                            disabled={props.checkboxDisabled}
                        />
                    </>
                )}
            </InputGroup>
        </Form.Group>
    </>
);

export default FormItemWithBold;
